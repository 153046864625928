
import { ref, defineComponent, computed } from "vue"
import store from "../store/index"
import toggle from "@/components/toggle.vue"
export default defineComponent({
    components: {
        "toggle-button": toggle,
    },
    setup() {
        const isClosed = ref(true)
        const tempSettings = ref([
            {
                name: "Celsius",
                value: "celsius",
            },
            {
                name: "Fahrenheit",
                value: "fahrenheit",
            },
        ])
        const themeSettings = ref([
            {
                name: "Light Mode",
                value: "light",
            },
            {
                name: "Dark Mode",
                value: "dark",
            },
            {
                name: "Dracula Theme",
                value: "dracula",
            },
        ])
        const newsSources = ref([
            {
                name: "NewsCatcher",
                value: "newscatcher",
            },
            {
                name: "Google News",
                value: "google",
            },
            {
                name: "NewsAPI",
                value: "newsapi",
            },
        ])

        const tempSetting = computed({
            get: () => store.state.tempSetting,
            set: (val) => store.dispatch("updateTempSetting", val),
        })
        const bothDegrees = computed({
            get: () => store.state.bothDegrees,
            set: (val) => store.dispatch("updateBothDegrees", val),
        })

        const theme = computed({
            get: () => store.state.theme,
            set: (val) => store.dispatch("updateTheme", val),
        })

        const newsSource = computed({
            get: () => store.state.newsSource,
            set: (val) => store.dispatch("updateNewsSource", val),
        })

        function toggleSidebar() {
            isClosed.value = !isClosed.value
        }
        return {
            isClosed,
            tempSetting,
            bothDegrees,
            theme,
            newsSource,
            newsSources,
            toggleSidebar,
            tempSettings,
            themeSettings,
        }
    },
})
