
import { defineComponent, PropType } from "vue"
import { NewsArticle } from "@/services/news"
import dayjs from "dayjs"

export default defineComponent({
    props: {
        article: {
            type: Object as () => PropType<NewsArticle>,
        },
    },
    setup() {
        function translateDate(datestr: string) {
            return dayjs(new Date(datestr)).toLocaleString()
        }
        return {
            translateDate,
        }
    },
})
