import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "app",
  class: "jumbotron jumbotron-fluid"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_sidebar = _resolveComponent("sidebar")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock("div", { class: _ctx.theme }, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_sidebar),
      _createVNode(_component_router_view)
    ])
  ], 2))
}