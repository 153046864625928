
import { defineComponent, computed } from "vue"
export default defineComponent({
    props: {
        label: String,
        id: String,
        modelValue: Boolean,
        disabled: Boolean,
        color: String,
        labelClass: String,
    },
    setup(props, { emit }) {
        const value = computed({
            get: () => props.modelValue,
            set: (modelValue) => emit("update:modelValue", modelValue),
        })
        return {
            value,
        }
    },
})
