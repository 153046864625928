
import { defineComponent, computed, onMounted } from "vue"
import weather from "@/components/weather.vue"
import searchbar from "@/components/searchbar.vue"
import newsComponent from "@/components/news.vue"
import store from "../store/index"
import { categories } from "@/services/news"
export default defineComponent({
    components: {
        weather,
        searchbar,
        news: newsComponent,
    },
    setup() {
        const loading = computed(
            () =>
                store.state.weather === undefined ||
                store.state.news === undefined
        )

        const category = computed<string | null>({
            get: () => store.state.selectedCategory,
            set: (val) => store.dispatch("updateCategory", val),
        })

        const source = computed(() => store.state.newsSource)

        const news = computed(() => {
            return store.state.news
                ? store.state.news.get(source.value + category.value)
                : undefined
        })

        const cats = computed(() => {
            if (source.value === "google") {
                return categories.google
            } else if (source.value === "newscatcher") {
                return categories.newscatcher
            } else {
                return categories.newsapi
            }
        })

        onMounted(() => {
            store.dispatch("fetchNews", false)
        })

        function switchCategory(cat: string) {
            category.value = cat
            store.dispatch("fetchNews", false)
        }
        return {
            loading,
            category,
            switchCategory,
            news,
            cats,
        }
    },
})
