
import { defineComponent, computed, onMounted } from "vue"
import store from "./store/index"
import sidebar from "@/components/sidebar.vue"
export default defineComponent({
    components: {
        sidebar,
    },
    setup() {
        const theme = computed(() => store.state.theme)

        onMounted(() => {
            setInterval(() => {
                store.dispatch("updateTime")
            }, 1000)
            store.dispatch("updateWeather")
        })

        return {
            theme,
        }
    },
})
