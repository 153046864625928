
import { defineComponent, ref } from "vue"
import Axios from "axios"
import jsonpAdapter from "axios-jsonp"

export default defineComponent({
    setup() {
        const searchInput = ref("")
        const dataService = ref<string[][]>([])

        function autocomplete() {
            const input = encodeURIComponent(searchInput.value)
            const uri = `https://suggestqueries.google.com/complete/search?q=${input}&client=chrome&hl=fr`
            Axios({
                url: uri,
                adapter: jsonpAdapter,
            }).then((res) => {
                dataService.value = []
                const items: string[] = [...res.data[1]]
                items.forEach((item) => {
                    const index = item.indexOf(searchInput.value.toLowerCase())
                    const part: string[] = []
                    if (index != -1) {
                        const first = item.substring(0, index)
                        const second = item.substring(
                            index,
                            index + searchInput.value.length
                        )
                        const third = item.substring(
                            index + searchInput.value.length,
                            item.length
                        )
                        part.push(first)
                        part.push(second)
                        part.push(third)
                        dataService.value.push(part)
                    } else {
                        part.push(item)
                        dataService.value.push(part)
                    }
                })
            })
        }

        function submit(suggestion: string[]) {
            // This code basically bypasses the Vue framework and does direct DOM manipulation.
            // Sorry. Sue me.
            searchInput.value = suggestion.join("")
            const searchbarform = ref<HTMLFormElement | null>(null)
            const searchinput = ref<HTMLInputElement | null>(null)

            if (searchinput.value) {
                searchinput.value.value = searchInput.value
            }
            if (searchbarform.value) searchbarform.value.submit()
            autocomplete()
        }
        return {
            searchInput,
            dataService,
            autocomplete,
            submit,
        }
    },
})
